import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type {
	CatalogProduct,
	Category,
	CatalogCollection,
	Textile,
	PlatformCatalogProduct,
	PlatformProduct,
} from "@/types/products";
import type { Order } from "@/types/filters";

export const usePM = () => {
	const {
		viewProduct,
		listCategoriesByPlatformTextilesApplication,
		listCompleteCollectionsByOrganizationCollection,
		viewTextile,
		viewOrganizationShowroomProductsCatalog,
		viewPlatformProductsPlatformsCatalog,
	} = usePMApi();

	const emptyPagination: Pagination = {
		page: 0,
		limit: 0,
		total: 0,
		order: "",
		page_count: 0,
		next: null,
		prev: null,
	};

	const listProductsForShowroom = async (
		org_id: string,
		limit: number,
		page: number
	): Promise<{ catalogProducts: CatalogProduct[]; pagination: Pagination }> => {
		const res = await viewOrganizationShowroomProductsCatalog({
			organization_id: org_id,
			platform_model: "Showroom",
			limit,
			page,
		});
		if (res.error) {
			console.error(res.error);
			return { catalogProducts: [], pagination: emptyPagination };
		}
		const catalogProducts = res.response?.data as CatalogProduct[];
		const pagination: Pagination = res.response?.pagination as Pagination;
		return { catalogProducts, pagination };
	};

	const listProductsForPlatform = async (
		platformId: string,
		page: number = 1,
		order: Order = {},
		limit: number = 21
	): Promise<{ platformCatalogProducts: PlatformCatalogProduct[]; pagination: Pagination }> => {
		const res = await viewPlatformProductsPlatformsCatalog(platformId, {
			limit,
			page,
			order,
		});
		if (res.error) {
			console.error(res.error);
			return { platformCatalogProducts: [], pagination: emptyPagination };
		}
		const platformProducts = res.response?.data as PlatformProduct;
		const platformCatalogProducts = platformProducts.platform_catalog_products as PlatformCatalogProduct[]; // remettre
		const pagination: Pagination = res.response?.pagination as Pagination;
		return { platformCatalogProducts, pagination };
	};

	const viewProductById = async (product_id: string) => {
		const res = await viewProduct(product_id);
		if (res.error) {
			console.error(res.error);
			return undefined;
		}
		const product = res.response?.data;
		return product;
	};

	const listCategoriesForShowroom = async (org_id: string) => {
		const res = await listCategoriesByPlatformTextilesApplication({
			organization_id: org_id,
			platform_model: "Showroom",
		});
		if (res.error) {
			console.error(res.error);
			return [];
		}
		const categories = res.response?.data as Category[];
		return categories;
	};

	const listCollections = async (org_id: string) => {
		const res = await listCompleteCollectionsByOrganizationCollection(org_id);

		if (res.error) {
			console.error(res.error);
			return [];
		}
		const collections = res.response?.data as CatalogCollection[];
		if (Array.isArray(collections)) {
			return collections.filter(c => c.visibility === true);
		} else {
			return [];
		}
	};

	const viewTextileById = async (textile_id: string) => {
		const res = await viewTextile(textile_id);
		if (res.error) {
			console.error(res.error);
			return undefined;
		}
		const textile = res.response?.data as Textile;
		return textile;
	};

	return {
		viewProductById,
		listCategoriesForShowroom,
		listCollections,
		listProductsForShowroom,
		viewTextileById,
		listProductsForPlatform,
	};
};
